import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/"
        }}>{`API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/pkg/"
        }}>{`Packages`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/pkg/core/"
            }}>{`Core`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/pkg/cli/"
            }}>{`CLI`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/pkg/wayback/"
            }}>{`Wayback`}</a></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      