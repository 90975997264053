import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from 'gatsby';
import Logo from '../components/Logo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "link-tologo-size32-link"
    }}><Link to='/' mdxType="Link"><Logo size={32} mdxType="Logo" /></Link></h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Home`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/cssstats"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mobile.twitter.com/cssstats"
        }}>{`Twitter`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      